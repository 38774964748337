import Index from './components/Index.vue';
import Product from './components/Product.vue';
import UAAlignment from './components/alignment/UA.vue';
import IAAAlignment from './components/alignment/IAA.vue';

export const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index,
    }, {
        name: 'Product',
        path: '/product',
        component: Product,
    }, {
        name: 'UAAlignment',
        path: '/alignment/UA',
        component: UAAlignment,
    }, {
        name: 'IAAAlignment',
        path: '/alignment/IAA',
        component: IAAAlignment,
    },
];