<template>
    <div>
        <el-select v-model="channel" filterable :disabled="loading" placeholder="渠道" style="width: 100px">
            <el-option v-for="c in channel_list" :value="c"></el-option>
        </el-select>
    </div>
    <el-table v-loading="loading" :data="dataFilter" style="margin-top: 10px">
        <el-table-column label="渠道" prop="iaa_platform" width="100"></el-table-column>
        <el-table-column label="app_id" prop="app_id" width="400"></el-table-column>
        <el-table-column label="platform" prop="platform" width="100"></el-table-column>
        <el-table-column label="产品" prop="product_id" width="400">
            <template #default="scope">
                <el-select v-model="scope.row.product_id" filterable @change="update(scope.row)" clearable>
                    <el-option v-for="p in product_list" :value="p.id" :label="`${p.name} (${p.nickname})`">
                        <img :src="p.avatar" style="width: 20px;vertical-align: middle"/>
                        {{ p.name }} ({{ p.nickname }})
                    </el-option>
                </el-select>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import axios from "ts-axios-new";

export default {
    name: "IAA",
    data() {
        return {
            loading: false, data: [], channel_list: [], channel: null, product_list: [],
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get('/api/v1/alignment/iaa').then(res => {
                    this.channel_list = [];
                    this.data = res.data.data.data;
                    this.data.forEach(d => {
                        if (!this.channel_list.includes(d.iaa_platform)) {
                            this.channel_list.push(d.iaa_platform);
                        }
                    });
                }),
                axios.get('/api/v1/product').then(res => {
                    this.product_list = res.data.data.productList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        update(row) {
            this.loading = true;
            axios.post('/api/v1/alignment/iaa', {
                channel: row.iaa_platform,
                platform: row.platform,
                app_id: row.app_id,
                product_id: row.product_id
            }).then(_ => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                return !this.channel || d.iaa_platform === this.channel;
            })
        }
    },
}
</script>

<style scoped>

</style>