<template>
    <div>
        <el-button type="primary" :loading="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table v-loading="loading" :data="data" style="margin-top: 10px">
        <el-table-column label="图片" prop="avatar" width="60">
            <template #default="scope">
                <el-image :src="scope.row.avatar" v-if="scope.row.avatar" lazy style="width: 50px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="ID" prop="id" width="150"></el-table-column>
        <el-table-column label="名称" prop="name" width="250">
            <template #default="scope">
                {{ scope.row.name }} ({{ scope.row.nickname }})
            </template>
        </el-table-column>
        <el-table-column label="AppID/PackageName" prop="app_id" width="250"></el-table-column>
        <el-table-column label="在线" prop="off" width="60">
            <template #default="scope">
                <el-icon v-if="scope.row.offline" style="color: var(--el-color-error)">
                    <CircleCloseFilled/>
                </el-icon>
                <el-icon v-else style="color: var(--el-color-success)">
                    <SuccessFilled/>
                </el-icon>
            </template>
        </el-table-column>
        <el-table-column label="数仓" prop="analytics_db" width="60">
            <template #default="scope">
                <el-icon v-if="scope.row.analytics_db" style="color: var(--el-color-success)">
                    <SuccessFilled/>
                </el-icon>
                <el-icon v-else style="color: var(--el-color-error)">
                    <CircleCloseFilled/>
                </el-icon>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="平台" prop="platform" :rules="[{required: true, message: '请选择平台'}]">
                <el-select v-model="form.platform" :disabled="loading" @change="changePlatform">
                    <el-option label="Android" value="android"></el-option>
                    <el-option label="iOS" value="ios"></el-option>
                </el-select>
            </el-form-item>
            <template v-if="form.platform === 'android'">
                <el-form-item label="包名" prop="app_id" :rules="[{required: true, message: '请输入包名'}]">
                    <el-input v-model="form.app_id" :disabled="loading" @change="changeAppId"></el-input>
                </el-form-item>
            </template>
            <template v-else-if="form.platform === 'ios'">
                <el-form-item label="AppId" prop="app_id" :rules="[{required: true, message: '请输入AppId'}]">
                    <el-input v-model="form.app_id" :disabled="loading" @change="changeAppId"
                              placeholder="十位数字"></el-input>
                </el-form-item>
                <el-form-item label="BundleID" prop="bundle_id" :rules="[{required: true, message: '请输入BundleId'}]">
                    <el-input v-model="form.bundle_id" :disabled="loading"></el-input>
                </el-form-item>
            </template>
            <el-form-item label="商店名" prop="name" v-if="form.name">
                <el-input v-model="form.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="昵称" prop="nickname" :rules="[{required: true, message: '请输入昵称'}]">
                <el-input v-model="form.nickname" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="Firebase" prop="gc_project" :rules="[{required: true, message: '请选择Firebase项目'}]">
                <el-select v-model="form.gc_project" filterable :disabled="loading" @change="changeProject">
                    <el-option :label="`${p.friendly_name} (${p.project_id})`" :value="p.project_id"
                               v-for="p in project_list"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="!!editing" label="已下线">
                <el-switch v-model="form.offline" :disabled="loading" :inactive-value="false"
                           :active-value="true" inactive-text="否" active-text="是"></el-switch>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../libs/utils";

export default {
    name: "Product",
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false, project_list: [],
            form: {
                app_id: '',
                bundle_id: '',
                platform: '',
                store: '',
                name: '',
                nickname: '',
                avatar: '',
                gc_project: '',
                analytics_db: '',
                url: '',
                offline: false,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get('/api/v1/product').then(res => {
                    this.data = res.data.data.productList;
                }),
                axios.get('/api/v1/gc/project').then(res => {
                    this.project_list = res.data.data.projectList;
                })
            ]).then(_ => {
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.editing = null;
            this.loading = this.dialog_opened = false;
            this.form = {
                app_id: '',
                bundle_id: '',
                platform: '',
                store: '',
                name: '',
                nickname: '',
                avatar: '',
                gc_project: '',
                analytics_db: '',
                url: '',
                offline: false,
            }
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/api/v1/product/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/api/v1/product`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            });
        },
        changeProject() {
            this.loading = true;
            axios.get(`/api/v1/gc/dataset`, {params: {project_id: this.form.gc_project}}).then(res => {
                this.form.analytics_db = res.data.data;
                this.loading = false;
            })
        },
        changePlatform() {
            if (this.form.platform === 'android') {
                this.form.store = 'google_play'
            } else {
                this.form.store = 'app_store'
            }
        },
        changeAppId() {
            if (this.form.platform === 'android') {
                this.form.bundle_id = this.form.app_id;
            }
            axios.post(`/api/v1/store`, this.form).then(res => {
                if (res.data.data) {
                    this.form.url = res.data.data.url;
                    this.form.name = res.data.data.name;
                    this.form.avatar = res.data.data.avatar;
                }
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>